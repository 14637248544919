import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBackIos, MdMenu } from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";

export default function Products({
  isMobile,
  products,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
}) {
  const [activeID, setActiveID] = useState("");
  const [internalID, setInternalID] = useState("");
  const [title_en, setTitle_en] = useState("");
  const [title_de, setTitle_de] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setInternalID(element.internal_id);
    setTitle_en(element.title_en);
    setTitle_de(element.title_de);
  };

  // handle upload. wenn aciveID=new, dann create, sonst update
  const handleUpload = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (activeID == "new") {
      const resa = await fetch(`${apiURL}/api/products/index.php`, {
        method: "PUT",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          internal_id: internalID,
          title_en: title_en,
          title_de: title_de,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    } else {
      const resa = await fetch(
        `${apiURL}/api/products/index.php?id=` + activeID,
        {
          method: "POST",
          headers: {
            //   Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            internal_id: internalID,
            title_en: title_en,
            title_de: title_de,
          }),
        }
      );

      await resa.text().then((value) => uploadEnds(value));
    }
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }
  };

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {location.pathname.substring(0, 6) == "/" ? (
            <div className="sidebar_top_navpoint_active">Products</div>
          ) : (
            <div className="sidebar_top_navpoint" onClick={() => navigate("/")}>
              Products
            </div>
          )}
          {location.pathname.substring(0, 4) == "/ads" ? (
            <div className="sidebar_top_navpoint_active"> Ad-Templates</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/ads")}
            >
              Ad-Templates
            </div>
          )}
          {location.pathname.substring(0, 6) == "/media" ? (
            <div className="sidebar_top_navpoint_active">Media</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/media")}
            >
              Media
            </div>
          )}
          {location.pathname.substring(0, 9) == "/creators" ? (
            <div className="sidebar_top_navpoint_active">Creators</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/creators")}
            >
              Creators
            </div>
          )}
          {location.pathname.substring(0, 10) == "/retention" ? (
            <div className="sidebar_top_navpoint_active">Retention</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/retention")}
            >
              Retention
            </div>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            {products.map((product) => (
              <>
                {product.id == activeID ? (
                  <div className="products_list_element_active">
                    <div className="products_list_element_col">
                      {product.internal_id}
                    </div>
                    <div className="products_list_element_col">
                      {product.title_en}
                    </div>
                  </div>
                ) : (
                  <div
                    className="products_list_element"
                    onClick={() => setActiveElement(product)}
                  >
                    <div className="products_list_element_col">
                      {product.internal_id}
                    </div>
                    <div className="products_list_element_col">
                      {product.title_en}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {isMobile && (
            <div className="" onClick={() => setActiveID("")}>
              X
            </div>
          )}
          <div className="products_addNew">
            {activeID == "new" ? (
              <div className="products_addNew_button_active">
                + Add New Product
              </div>
            ) : (
              <div
                className="products_addNew_button"
                onClick={() => setActiveID("new")}
              >
                + Add New Product
              </div>
            )}
          </div>

          <div className="">
            {activeID == "new" ? "Neues Produkt anlegen" : ""}
          </div>
          <div className=""> internal id</div>
          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={internalID}
              onChange={(e) => setInternalID(e.target.value)}
            />
          </div>
          <div className=""> title_en</div>
          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={title_en}
              onChange={(e) => setTitle_en(e.target.value)}
            />
          </div>
          <div className=""> title_de</div>
          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={title_de}
              onChange={(e) => setTitle_de(e.target.value)}
            />
          </div>
          {activeID != "" && (
            <div className="savebtn" onClick={() => handleUpload()}>
              {isLoading ? "Lädt..." : "Speichern"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
