import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBackIos, MdCancel, MdImage, MdMenu } from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";

export default function Media({
  isMobile,
  media,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
}) {
  const [activeID, setActiveID] = useState("");
  const [internalID, setInternalID] = useState("");
  const [title_en, setTitle_en] = useState("");
  const [title_de, setTitle_de] = useState("");
  const [imageUploads, setImageUploads] = useState([]);
  const [mediaImageURL, setMediaImageURL] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setTitle_en(element.title);
    setMediaImageURL(element.url);
  };

  // handle upload. wenn aciveID=new, dann create, sonst update
  const handleUpload = async (imgURL) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (activeID == "new") {
      const resa = await fetch(`${apiURL}/api/media/index.php`, {
        method: "PUT",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          title: title_en,
          url: imgURL,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    } else {
      const resa = await fetch(`${apiURL}/api/media/index.php?id=` + activeID, {
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          title: title_en,
          url: imgURL,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    }
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }

    if (value != "") {
      setImageUploads([]);
    }
  };

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  const insertLocalImage = (e) => {
    let file = e.target.files[0];
    let filetype = "image";

    setImageUploads({
      localURL: URL.createObjectURL(file),
      files: file,
      filetype: filetype,
    });
  };

  const handleUploadForImage = async () => {
    setIsLoading(true);

    const files = document.getElementById("imgInp").files;
    const formData = new FormData();
    if (!isEmpty(imageUploads)) {
      setMediaImageURL(imageUploads.localURL);
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    } else {
      handleUpload(mediaImageURL);
    }
  };

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {location.pathname.substring(0, 6) == "/" ? (
            <div className="sidebar_top_navpoint_active">Products</div>
          ) : (
            <div className="sidebar_top_navpoint" onClick={() => navigate("/")}>
              Products
            </div>
          )}
          {location.pathname.substring(0, 4) == "/ads" ? (
            <div className="sidebar_top_navpoint_active"> Ad-Templates</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/ads")}
            >
              Ad-Templates
            </div>
          )}
          {location.pathname.substring(0, 6) == "/media" ? (
            <div className="sidebar_top_navpoint_active">Media</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/media")}
            >
              Media
            </div>
          )}
          {location.pathname.substring(0, 9) == "/creators" ? (
            <div className="sidebar_top_navpoint_active">Creators</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/creators")}
            >
              Creators
            </div>
          )}
          {location.pathname.substring(0, 10) == "/retention" ? (
            <div className="sidebar_top_navpoint_active">Retention</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/retention")}
            >
              Retention
            </div>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            {media.map((product) => (
              <>
                {product.id == activeID ? (
                  <div className="products_list_element_active">
                    <div className="products_list_element_col">
                      <img
                        src={product.url}
                        style={({ width: "40px" }, { height: "40px" })}
                      />
                    </div>
                    <div className="products_list_element_col">
                      {product.title}
                    </div>
                  </div>
                ) : (
                  <div
                    className="products_list_element"
                    onClick={() => setActiveElement(product)}
                  >
                    <div className="products_list_element_col">
                      <img
                        src={product.url}
                        style={({ width: "40px" }, { height: "40px" })}
                      />
                    </div>
                    <div className="products_list_element_col">
                      {product.title}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {isMobile && (
            <div className="" onClick={() => setActiveID("")}>
              X
            </div>
          )}
          <div className="products_addNew">
            {activeID == "new" ? (
              <div className="products_addNew_button_active">
                + Add New Product
              </div>
            ) : (
              <div
                className="products_addNew_button"
                onClick={() => setActiveID("new")}
              >
                + Add New Product
              </div>
            )}
          </div>

          <div className="">
            {activeID == "new" ? "Neues Produkt anlegen" : ""}
          </div>
          <div className=""> image</div>
          {!isEmpty(imageUploads) ? (
            <div className="">
              <img
                className="createService_inputBtn_img"
                style={{ width: "calc(100% - 24px)" }}
                src={imageUploads.localURL}
              />

              <div className="createService_inputBtn_cancelBtn">
                <CancelIcon onClick={() => setImageUploads([])} />
              </div>
            </div>
          ) : (
            <>
              <img
                className="createService_inputBtn_img"
                style={{ width: "calc(100% - 24px)" }}
                src={mediaImageURL}
              />
              <label htmlFor={"imgInp"} className="">
                <div className="createService_inputlabel">
                  <ImageIcon /> Content hochladen
                </div>
              </label>
            </>
          )}
          <div className="inputrow">
            <input
              type="file"
              accept="image/*"
              id={"imgInp"}
              name="files[]"
              className="createService_inputBtn d-none"
              onChange={(e) => insertLocalImage(e)}
            />
          </div>
          <div className=""> title_en</div>
          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={title_en}
              onChange={(e) => setTitle_en(e.target.value)}
            />
          </div>
          {activeID != "" && (
            <div className="savebtn" onClick={() => handleUploadForImage()}>
              {isLoading ? "Lädt..." : "Speichern"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 33px;
  color: #575757;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
