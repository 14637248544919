import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Products from "./screens/Products";
import AdTemplates from "./screens/AdTemplates";
import Media from "./screens/Media";
import Creators from "./screens/Creators";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.letsfluence.com";
  if (developerMode) {
    apiURL = "https://developercloud.letsfluence.com";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const [activeProductID, setActiveProductID] = useState(6502);
  const [activeCreator, setActiveCreator] = useState([]);
  const [cart, setCart] = useState([]);

  const [uploading, setIsUploading] = useState(false);

  const isMobile = useScreenType();

  const [reloadProducts, setReloadProducts] = useState(false);
  const [reloadTemplates, setReloadTemplates] = useState(false);
  const [reloadMedia, setReloadMedia] = useState(false);
  const [reloadCreators, setReloadCreators] = useState(false);

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setAuthToken();
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken] // Additional dependencies, if needed
  );

  const {
    data: creators,
    loading: loadingCreatorData,
    error: errorCreatorData,
  } = useFetch(
    `${apiURL}/api/creators/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadCreators] // Additional dependencies, if needed
  );

  const {
    data: templates,
    loading: loadingServiceCategories,
    error: errorServiceCategories,
  } = useFetch(
    `${apiURL}/api/templates/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadTemplates] // Additional dependencies, if needed
  );

  const {
    data: products,
    loading: loadingProducts,
    error: errorProducts,
  } = useFetch(
    `${apiURL}/api/products/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadProducts] // Additional dependencies, if needed
  );

  const {
    data: media,
    loading: loadingMedia,
    error: errorMedia,
  } = useFetch(
    `${apiURL}/api/media/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadMedia] // Additional dependencies, if needed
  );

  /*

  const userData = [];
  const companyData = [];
  const services = [];
  const serviceCategories = [];
  */

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Products
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={products}
                  reloadProducts={reloadProducts}
                  setReloadProducts={setReloadProducts}
                />
              }
            />
            <Route
              path="/ads"
              element={
                <AdTemplates
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  templates={templates}
                  reloadProducts={reloadTemplates}
                  setReloadProducts={setReloadTemplates}
                  products={products}
                  media={media}
                />
              }
            />
            <Route
              path="/media"
              element={
                <Media
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  media={media}
                  reloadProducts={reloadMedia}
                  setReloadProducts={setReloadMedia}
                />
              }
            />
            <Route
              path="/creators"
              element={
                <Creators
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={products}
                  reloadProducts={reloadCreators}
                  setReloadProducts={setReloadCreators}
                />
              }
            />
            <Route
              path="/retention"
              element={
                <Products
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  templates={templates}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={products}
                  reloadProducts={reloadProducts}
                  setReloadProducts={setReloadProducts}
                />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
