import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBackIos, MdEdit, MdMenu } from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";
import ProductName from "../components/ProductName";
import ProductFilterByID from "../components/ProductName";
import StoryElement from "../components/StoryElement";

export default function AdTemplates({
  isMobile,
  templates,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
  products,
  media,
}) {
  const [activeID, setActiveID] = useState("");
  const [product_internal_id, setProduct_internal_id] = useState("");
  const [title_en, setTitle_en] = useState("");
  const [title_de, setTitle_de] = useState("");
  const [description_en, setDescription_en] = useState("");
  const [description_de, setDescription_de] = useState("");
  const [publish_channel, setPublish_channel] = useState("");
  const [publish_format, setPublish_format] = useState("");
  const [category, setCategory] = useState("");
  const [stories, setStories] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setProduct_internal_id(element.product_internal_id);
    setTitle_en(element.title_en);
    setTitle_de(element.title_de);
    setDescription_en(element.description_en);
    setDescription_de(element.description_de);
    setPublish_channel(element.publish_channel);
    setPublish_format(element.publish_format);
    setStories(JSON.parse(element.stories));
    setCategory(element.category);
  };

  // handle upload. wenn aciveID=new, dann create, sonst update
  const handleUpload = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (activeID == "new") {
      const resa = await fetch(`${apiURL}/api/templates/index.php`, {
        method: "PUT",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          product_internal_id: product_internal_id,
          title_en: title_en,
          title_de: title_de,
          stories: JSON.stringify(stories),
          category: category,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    } else {
      const resa = await fetch(
        `${apiURL}/api/templates/index.php?id=` + activeID,
        {
          method: "POST",
          headers: {
            //   Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            product_internal_id: product_internal_id,
            title_en: title_en,
            title_de: title_de,
            stories: JSON.stringify(stories),
            category: category,
          }),
        }
      );

      await resa.text().then((value) => uploadEnds(value));
    }
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }
  };

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  const addNewTemplate = () => {
    let helpArray = stories;
    const newArray = [];
    helpArray.map((object) => newArray.push(object));
    newArray.push({
      url: "https://www.developercloud.letsfluence.com/api/images/placeholder/happy-icon.svg",
      description: "do this or that",
    });
    setStories(newArray);
  };

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {location.pathname.substring(0, 6) == "/" ? (
            <div className="sidebar_top_navpoint_active">Products</div>
          ) : (
            <div className="sidebar_top_navpoint" onClick={() => navigate("/")}>
              Products
            </div>
          )}
          {location.pathname.substring(0, 4) == "/ads" ? (
            <div className="sidebar_top_navpoint_active"> Ad-Templates</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/ads")}
            >
              Ad-Templates
            </div>
          )}
          {location.pathname.substring(0, 6) == "/media" ? (
            <div className="sidebar_top_navpoint_active">Media</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/media")}
            >
              Media
            </div>
          )}
          {location.pathname.substring(0, 9) == "/creators" ? (
            <div className="sidebar_top_navpoint_active">Creators</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/creators")}
            >
              Creators
            </div>
          )}
          {location.pathname.substring(0, 10) == "/retention" ? (
            <div className="sidebar_top_navpoint_active">Retention</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/retention")}
            >
              Retention
            </div>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            {templates.map((product) => (
              <>
                {product.id == activeID ? (
                  <div className="products_list_element_active">
                    <div className="products_list_element_col">
                      <ProductFilterByID
                        productsdata={products}
                        product_internal_id={product.product_internal_id}
                        rerenderOn={templates}
                      />
                    </div>
                    <div className="products_list_element_col">
                      {product.title_en}
                    </div>
                  </div>
                ) : (
                  <div
                    className="products_list_element"
                    onClick={() => setActiveElement(product)}
                  >
                    <div className="products_list_element_col">
                      <ProductFilterByID
                        productsdata={products}
                        product_internal_id={product.product_internal_id}
                        rerenderOn={templates}
                      />
                    </div>
                    <div className="products_list_element_col">
                      {product.title_en}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {isMobile && (
            <div className="" onClick={() => setActiveID("")}>
              X
            </div>
          )}
          <div className="products_addNew">
            {activeID == "new" ? (
              <div className="products_addNew_button_active">
                + Add New Ad-Template
              </div>
            ) : (
              <div
                className="products_addNew_button"
                onClick={() => setActiveID("new")}
              >
                + Add New Ad-Template
              </div>
            )}
          </div>

          <div className="">
            {activeID == "new" ? "Neues Produkt anlegen" : ""}
          </div>
          <div className=""> product_internal_id</div>
          <div className="inputrow">
            <select
              onChange={(e) => setProduct_internal_id(e.target.value)}
              className="inputrow_input"
            >
              {products.map((product) => (
                <>
                  {product_internal_id == product.internal_id && (
                    <option value={product.internal_id} selected>
                      {product.title_en}
                    </option>
                  )}
                  <option value={product.internal_id}>
                    {product.title_en}
                  </option>
                </>
              ))}
            </select>
          </div>
          <div className=""> title_en</div>
          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={title_en}
              onChange={(e) => setTitle_en(e.target.value)}
            />
          </div>
          <div className=""> title_de</div>
          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={title_de}
              onChange={(e) => setTitle_de(e.target.value)}
            />
          </div>
          <div className=""> description_en</div>

          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={description_en}
              onChange={(e) => setDescription_en(e.target.value)}
            />
          </div>
          <div className=""> description_de</div>

          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={description_de}
              onChange={(e) => setDescription_de(e.target.value)}
            />
          </div>
          <div className=""> category</div>

          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>

          <div className="stories">
            {stories.map((story, index) => (
              <StoryElement
                story={story}
                stories={stories}
                setStories={setStories}
                media={media}
                index={index}
                key={index}
              />
            ))}
            <div
              className="products_addNew_button"
              onClick={() => addNewTemplate()}
            >
              Add Story Scene
            </div>
          </div>

          {activeID != "" && (
            <div className="savebtn" onClick={() => handleUpload()}>
              {isLoading ? "Lädt..." : "Speichern"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const EditIcon = styled(MdEdit)`
  font-size: 33px;
  color: #575757;
`;
