import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdDelete, MdEdit } from "react-icons/md";

export default function StoryElement({
  story,
  stories,
  setStories,
  media,
  rerenderOn,
  index,
}) {
  // This function takes all the products and filters back one product by ID
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!isEmpty(story)) {
      setUrl(story.url);
      setDescription(story.description);
    }
  }, [story]);

  const setNewImage = (imgURL) => {
    let updatedObject = { url: imgURL, description: description };

    if (!isEmpty(story) && !isEmpty(stories)) {
      let helpArray = stories;
      let newArray = [];

      if (!isEmpty(stories) && !isEmpty(story)) {
        for (let i = 0; i < helpArray.length; i++) {
          if (i == index) {
            newArray.push(updatedObject);
          } else {
            newArray.push(helpArray[i]);
          }
        }
      }
      setStories(newArray);
      setUrl(imgURL);
    }
  };

  const setNewDescription = (descr) => {
    let updatedObject = { url: url, description: descr };

    if (!isEmpty(story) && !isEmpty(stories)) {
      let helpArray = stories;
      let newArray = [];

      if (!isEmpty(stories) && !isEmpty(story)) {
        for (let i = 0; i < helpArray.length; i++) {
          if (i == index) {
            newArray.push(updatedObject);
          } else {
            newArray.push(helpArray[i]);
          }
        }
      }
      setStories(newArray);
      setDescription(descr);
    }
  };

  const removeThisStory = () => {
    if (!isEmpty(story) && !isEmpty(stories)) {
      let helpArray = stories;
      let newArray = [];

      if (!isEmpty(stories) && !isEmpty(story)) {
        for (let i = 0; i < helpArray.length; i++) {
          if (i == index) {
          } else {
            newArray.push(helpArray[i]);
          }
        }
      }
      setStories(newArray);
    }
  };

  return (
    <div className="story_object">
      <div className="story_object_left">
        <img src={url} style={({ width: "100px" }, { height: "100px" })} />
        <div className="inputrow">
          <select
            onChange={(e) => setNewImage(e.target.value)}
            className="inputrow_input"
          >
            {media.map((medi) => (
              <option value={medi.url}>{medi.title}</option>
            ))}
          </select>
        </div>
        <div className="button" onClick={() => removeThisStory()}>
          <DeleteIcon />
        </div>
      </div>
      <div className="story_object_right">
        <input
          type="text"
          className="inputrow_input"
          value={description}
          onChange={(e) => setNewDescription(e.target.value)}
        />
      </div>
    </div>
  );
}

const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #575757;
`;
