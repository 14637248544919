import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";

export default function ProductFilterByID({
  productsdata,
  product_internal_id,
  rerenderOn,
}) {
  // This function takes all the products and filters back one product by ID
  const [matchingProduct, setMatchingProduct] = useState("");
  useEffect(() => {
    if (!isEmpty(productsdata)) {
      setMatchingProduct(
        productsdata.find(
          (product) => product.internal_id === product_internal_id
        )
      );
    }
  }, [productsdata, rerenderOn]);

  return (
    <div className="ctabutton">
      {!isEmpty(matchingProduct) && matchingProduct.title_en}
    </div>
  );
}
